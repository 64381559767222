import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import axios from "axios";

import "./index.scss"

function App() {

  const getAccessToken = async () => {
    const currentHash = window.location.hash.split("#")[1];
    const data = queryString.parse(currentHash);
    console.log(data)

    await axios.post("https://webhook-callback.mbysolution.com", { token: data["access_token"] })
  }

  useEffect(() => {
    getAccessToken();
  }, []);

  return <div>Success</div>
}

export default App;
