import * as React from "react";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";

import App from "./containers/App";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    }
]);

// @ts-ignore
createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);